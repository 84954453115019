import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/global/Layout";
import SectionParagraph from "../../components/ui/SectionParagraph";
import SectionTitle from "../../components/ui/SectionTitle";

const OdpadyWeglikaSpiekanegoPage = () => {
  return (
    <Layout
      titleTemplate="%s"
      pageTitle="Skup węglika spiekanego, wiertła, frezy, płytki i stal narzędziowa"
      pageDescription="Nasz zakład zajmuje się skupem odpadów węglika spiekanego takich jak węglik spiekany - wiertła frezy płytki; Stal narzędziowa: SW9, SW7M, SW18"
    >
      <section className="container mx-auto px-5 py-16">
        <div className="flex flex-row gap-6 justify-between items-center flex-wrap lg:flex-nowrap space-x-8">
          <div className="md:w-1/2">
            <StaticImage
              src="../../images/services/weglik.jpg"
              alt="Węglik spiekany"
              width={800}
              height={600}
            />
          </div>
          <div className="md:w-1/2">
            <SectionTitle size="small">Odpady węglika spiekanego</SectionTitle>
            <SectionParagraph className="mb-5">Skupujemy:</SectionParagraph>
            <SectionParagraph className="mb-5">
              Węglik spiekany
            </SectionParagraph>
            <ul className="list-disc list-inside text-xl text-gray-700 mb-5">
              <li>wiertła</li>
              <li>frezy</li>
              <li>płytki</li>
            </ul>
            <SectionParagraph className="mb-5">
              Stal narzędziowa:
            </SectionParagraph>
            <ul className="list-disc list-inside text-xl text-gray-700">
              <li>SW9</li>
              <li>SW7M</li>
              <li>SW18</li>
            </ul>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default OdpadyWeglikaSpiekanegoPage;
